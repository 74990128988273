<template>
  <div>
    <!-- 页头 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>停车数据</el-breadcrumb-item>
      <el-breadcrumb-item
        v-if="$route.query.totalTime"
        :to="{ path: '/Billdaysummary' }"
        >账单日汇总</el-breadcrumb-item
      >
      <el-breadcrumb-item>订单统计</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData" v-loading="loading">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pkId"
            filterable
            placeholder="请选择"
            style="width: 150px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >车牌号码：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入车牌号码"
            v-model="query.plateNo"
            clearable
          />
        </label>
        <label style="margin: 10px" for=""
          >订单号：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入订单号"
            v-model="query.orderNo"
            clearable
          />
        </label>
        <label style="margin: 10px" for="" v-if="!$route.query.totalTime"
          >订单时间：<el-date-picker
            v-model="inTime"
            @change="inTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择开始时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="outTime"
            @change="outTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择结束时间"
          >
          </el-date-picker
        ></label>
        <el-button size="small" type="primary" @click="findBillcx"
          >查询</el-button
        >
        <el-button size="small" type="primary" @click="findBillcz"
          >重置</el-button
        >
      </div>
      <!-- 点击添加显示对话框 -->
      <el-button type="primary" size="small" @click="exportOrderInfo"
        >导出</el-button
      >
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="orderNo"
            label="订单号"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="plateNo"
            label="车牌号"
            width="90"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="inTime"
            label="进出时间"
            width="150"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.pkInoutData">{{
                conversionTime(scope.row.pkInoutData.inTime)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="outTime"
            label="离场时间"
            width="150"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.pkInoutData">{{
                scope.row.pkInoutData.outTime
                  ? conversionTime(scope.row.pkInoutData.outTime)
                  : ''
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="停车时长"
            width="150"
          >
            <template slot-scope="scope">
              <span
                v-if="scope.row.pkInoutData && scope.row.pkInoutData.outTime"
                >{{
                  timeFn(
                    scope.row.pkInoutData.inTime,
                    scope.row.pkInoutData.outTime
                  )
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="orderTotalMoney"
            label="订单金额"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponMoney"
            label="优惠券面额"
            width="100"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponMoney"
            label="实际抵扣金额"
            width="110"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.orderTotalMoney > scope.row.couponMoney
                  ? scope.row.couponMoney
                  : scope.row.orderTotalMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="actuallyPayMoney"
            label="应收金额"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="actuallyPayMoney"
            label="实收金额"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="phone"
            label="手机收款金额"
            width="110"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="hangzhouCity"
            label="城市大脑金额"
            width="110"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="cash"
            label="现金金额"
          ></el-table-column>
        </el-table>
      </transition>
      <div v-if="jineList" style="text-align: right; font-weight: 600">
        订单总金额:{{
          jineList.total ? jineList.total : '0'
        }}元，实际优惠抵扣:{{
          jineList.coupon ? jineList.coupon : '0'
        }}元，应收:{{
          jineList.receivableMoney ? jineList.receivableMoney : '0'
        }}元，实收:{{ jineList.actually ? jineList.actually : '0' }}元，微信:{{
          jineList.wxPay ? jineList.wxPay : '0'
        }}元，支付宝:{{
          jineList.alipay ? jineList.alipay : '0'
        }}元，聚合支付:{{
          jineList.ccb ? jineList.ccb : '0'
        }}元，杭州城市大脑:{{
          jineList.hangzhouCity ? jineList.hangzhouCity : '0'
        }}元，现金支付:{{ jineList.cash ? jineList.cash : '0' }}元，ETC支付:{{
          jineList.etc ? jineList.etc : '0'
        }}元
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { statisticsAmount, findBill, exportOrderInfo } from '@/api/common.js'
import { findPkInfoSelect } from '@/api/Parkinglot.js'
import qs from 'qs'
export default {
  data() {
    return {
      loading: false,
      isShowData: false,
      tableData: [],
      inTime: undefined, // 开始时间(时间戳）
      outTime: undefined, // 结束时间(时间戳)
      query: {
        size: 10,
        current: 1,
        pkId: undefined,
        plateNo: undefined, // 车牌号
        orderNo: undefined, // 订单编号
        payStatus: 2, // 支付状态
        inTime: undefined, // 开始时间(时间戳）
        outTime: undefined, // 结束时间(时间戳)
      },
      total: undefined,
      jineList: undefined,
      baseUrlDC: process.env.VUE_APP_BASE_API,
      PkInfoSelect: [], // 保存停车场下拉框
    }
  },
  created() {
    this.findPkInfoSelect()
  },
  methods: {
    inTimechange() {
      this.outTime = this.inTime + 3600 * 1000 * 24 * 7 - 1000
    },
    outTimechange() {
      if (this.inTime === null || this.inTime === undefined) {
        this.outTime = null
        return this.$message.warning('请选择开始时间')
      } else if (this.outTime < this.inTime) {
        this.outTime = this.inTime + 3600 * 1000 * 24 * 1
        return this.$message.warning('不可选择小于开始时间的日期')
      } else if (this.outTime - this.inTime > 3600 * 1000 * 24 * 7) {
        this.outTime = this.inTime + 3600 * 1000 * 24 * 7 - 1000
        return this.$message.warning('不可选择比开始时间超过七天以外的日期')
      }
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          if (this.$route.query.pkId) {
            this.query.pkId = parseInt(this.$route.query.pkId)
          } else {
            this.query.pkId = this.PkInfoSelect[0].id
          }
          this.getTimeFn()
        }
      })
    },
    exportOrderInfo() {
      if (this.$route.query.totalTime) {
        this.query.inTime = this.$route.query.totalTime
        this.query.outTime =
          parseInt(this.$route.query.totalTime) + (24 * 3600 * 1000 - 1)
      } else {
        if (this.outTime - this.inTime > 3600 * 1000 * 24 * 7) {
          return this.$message.warning('只能导出一个礼拜以内的数据')
        }
        this.query.inTime = this.inTime
        this.query.outTime = this.outTime
      }
      if (this.query.inTime || this.query.outTime) {
        this.query.inTime = (this.query.inTime / 1000).toFixed(0)
        this.query.outTime = (this.query.outTime / 1000).toFixed(0)
      }
      window.open(
        this.baseUrlDC +
          '/parking/saas/orderInfo/exportOrderInfo?' +
          qs.stringify({
            pkId: this.query.pkId,
            inTime: this.query.inTime,
            outTime: this.query.outTime,
            plateNo: this.query.plateNo,
            orderNo: this.query.orderNo,
            payStatus: this.query.payStatus,
          })
      )
    },
    statisticsAmount() {
      // 统计金额
      statisticsAmount(this.query, { pk_id: this.query.pkId }).then((res) => {
        this.jineList = res.data.data
      })
    },
    // 计算两个时间差 dateBegin 开始时间
    timeFn(dateBegin, dateEnd) {
      //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
      var dateDiff = dateEnd * 1000 - dateBegin * 1000 //时间差的毫秒数时间戳
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)) //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)) //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)) //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000)
      var leave4 = leave3 % (60 * 1000) //计算分钟数后剩余的毫秒数
      var minseconds = Math.round(leave4 / 1000)
      // var timeFn = "耗时："+dayDiff+"天 "+hours+"小时 "+minutes+" 分钟"+seconds+" 秒"+minseconds+"毫秒";
      var timeFn =
        dayDiff + '天' + hours + '小时' + minutes + '分钟' + seconds + '秒'
      return timeFn
    },
    findBill() {
      // list
      if (this.$route.query.totalTime) {
        this.query.inTime = this.$route.query.totalTime
        this.query.outTime =
          parseInt(this.$route.query.totalTime) + (24 * 3600 * 1000 - 1000)
      } else {
        this.query.inTime = this.inTime
        this.query.outTime = this.outTime
      }
      if (this.query.inTime && !this.query.outTime) {
        return this.$message.warning('请输入截止时间')
      } else if (!this.query.inTime && this.query.outTime) {
        return this.$message.warning('请输入开始时间')
      }
      if (this.query.inTime || this.query.outTime) {
        this.query.inTime = (this.query.inTime / 1000).toFixed(0)
        this.query.outTime = (this.query.outTime / 1000).toFixed(0)
      }
      findBill(this.query, { pk_id: this.query.pkId }).then((res) => {
        if (res.data.code === '200') {
          this.total = res.data.data.total
          this.tableData = res.data.data.list
          this.statisticsAmount()
          this.isShowData = true
          this.loading = false
        }
      })
    },
    getTimeFn() {
      // 默认显示前1天0点的数据
      this.inTime =
        new Date(new Date().toLocaleDateString()).getTime() -
        3600 * 1000 * 24 * 6
      this.outTime =
        new Date(new Date().toLocaleDateString()).getTime() +
        24 * 60 * 60 * 1000 -
        1000
      this.findBill()
    },
    findBillcx() {
      // 查询
      this.query.current = 1
      this.findBill()
    },
    findBillcz() {
      // 重置
      this.query.plateNo = undefined
      this.query.orderNo = undefined
      this.query.pkId = this.PkInfoSelect[0].id
      this.getTimeFn()
    },
    handleSizeChange(val) {
      this.loading = true
      this.query.size = val
      this.findBill()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      this.loading = true
      this.query.current = val
      this.findBill()
      console.log(`当前页: ${val}`)
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
